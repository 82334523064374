import { BaseAPi } from '../../../configs/BaseApi';
import HttpAccountController from './httpAccountController';
import HttpCartController from './httpCartController';
import HttpCategoryController from './httpCategoryController';
import HttpCommentController from './httpCommentController';
import HttpDiscountController from './httpDiscountController';
import HttpNewController from './httpNewsController';
import HttpProducerController from './httpProducerController';
import HttpProductController from './httpProductController';
import HttpProviceController from './httpProvinceController';
import HttpStatisticalController from './httpStatisticalController';
import HttpUserAddressController from './httpUserAddressController';
import HttpVoucherController from './httpVoucherController';
import HttpPaymentController from './httppaymentController';

export const httpProduct = new HttpProductController(BaseAPi);
export const httpCategory = new HttpCategoryController(BaseAPi);
export const httpProducer = new HttpProducerController(BaseAPi);
export const httpDiscount = new HttpDiscountController(BaseAPi);
export const httpVoucher = new HttpVoucherController(BaseAPi);
export const httpProvince = new HttpProviceController(BaseAPi);
export const httpComment = new HttpCommentController(BaseAPi);
export const httpCart = new HttpCartController(BaseAPi);
export const httpStatistical = new HttpStatisticalController(BaseAPi);
export const httpAccount = new HttpAccountController(BaseAPi);
export const httpPayment = new HttpPaymentController(BaseAPi);
export const httpUserAddress = new HttpUserAddressController(BaseAPi);
export const httpNews = new HttpNewController(BaseAPi);
