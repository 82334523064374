export const image = {
    logo: require('./images/logo.png'),
    logoFooter: require('./images/logoFooter.png'),
    price: require('./images/price.png'),
    menu: require('./images/menu.png'),
    category: require('./images/category.png'),
    quantityOrder: require('./images/quantityOrder.png'),
    quantityCustomer: require('./images/quantityCustomer.png'),
    sale: require('./images/sale.png'),
    producer: require('./images/producer.png'),
    quantityProduct: require('./images/quantiyProduct.png'),
    new: require('./images/New.png'),
    folder: require('./images/folder.png'),
    Fire: require('./images/Fire.png'),
    CategoryIcon: require('./images/categoryIcon.png'),
    notfound: require('./images/notfound.jpeg'),
    banner1: require('./images/banner1.jpg'),
    banner2: require('./images/banner2.jpg'),
    banner3: require('./images/banner3.jpg'),
    bannertop: require('./images/bannertop.png'),
};
