export const CoppyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="26" viewBox="0 0 12 13" fill="none">
            <path
                d="M3.5 4.30005C2.96957 4.30005 2.46086 4.51076 2.08579 4.88584C1.71071 5.26091 1.5 5.76962 1.5 6.30005C1.5 6.83048 1.71071 7.33919 2.08579 7.71426C2.46086 8.08934 2.96957 8.30005 3.5 8.30005H4.5C4.63261 8.30005 4.75978 8.35273 4.85355 8.4465C4.94732 8.54026 5 8.66744 5 8.80005C5 8.93266 4.94732 9.05983 4.85355 9.1536C4.75978 9.24737 4.63261 9.30005 4.5 9.30005H3.5C2.70435 9.30005 1.94129 8.98398 1.37868 8.42137C0.81607 7.85876 0.5 7.0957 0.5 6.30005C0.5 5.5044 0.81607 4.74134 1.37868 4.17873C1.94129 3.61612 2.70435 3.30005 3.5 3.30005H4.5C4.63261 3.30005 4.75978 3.35273 4.85355 3.4465C4.94732 3.54026 5 3.66744 5 3.80005C5 3.93266 4.94732 4.05983 4.85355 4.1536C4.75978 4.24737 4.63261 4.30005 4.5 4.30005H3.5ZM7 3.80005C7 3.66744 7.05268 3.54026 7.14645 3.4465C7.24021 3.35273 7.36739 3.30005 7.5 3.30005H8.5C9.29565 3.30005 10.0587 3.61612 10.6213 4.17873C11.1839 4.74134 11.5 5.5044 11.5 6.30005C11.5 7.0957 11.1839 7.85876 10.6213 8.42137C10.0587 8.98398 9.29565 9.30005 8.5 9.30005H7.5C7.36739 9.30005 7.24021 9.24737 7.14645 9.1536C7.05268 9.05983 7 8.93266 7 8.80005C7 8.66744 7.05268 8.54026 7.14645 8.4465C7.24021 8.35273 7.36739 8.30005 7.5 8.30005H8.5C9.03043 8.30005 9.53914 8.08934 9.91421 7.71426C10.2893 7.33919 10.5 6.83048 10.5 6.30005C10.5 5.76962 10.2893 5.26091 9.91421 4.88584C9.53914 4.51076 9.03043 4.30005 8.5 4.30005H7.5C7.36739 4.30005 7.24021 4.24737 7.14645 4.1536C7.05268 4.05983 7 3.93266 7 3.80005ZM3.5 6.30005C3.5 6.16744 3.55268 6.04026 3.64645 5.9465C3.74021 5.85273 3.86739 5.80005 4 5.80005H8C8.13261 5.80005 8.25979 5.85273 8.35355 5.9465C8.44732 6.04026 8.5 6.16744 8.5 6.30005C8.5 6.43266 8.44732 6.55983 8.35355 6.6536C8.25979 6.74737 8.13261 6.80005 8 6.80005H4C3.86739 6.80005 3.74021 6.74737 3.64645 6.6536C3.55268 6.55983 3.5 6.43266 3.5 6.30005Z"
                fill="#4C4C4C"
            />
        </svg>
    );
};
